import { Capacitor } from '@capacitor/core';
import { useScript } from 'helpers/utils';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const LoadAdvAdKaora = () => {
  const location = useLocation();

  const initialTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    try {
      if (!Capacitor.isNativePlatform()) {
        // Clear any existing initial timeout
        if (initialTimeoutRef.current) {
          clearTimeout(initialTimeoutRef.current);
        }

        // Set the initial timeout for the first execution after 1000ms
        initialTimeoutRef.current = setTimeout(() => {
          if (typeof window.adkSpaReload === 'function') {
            window.adkSpaReload();
            //console.log('adkSpaReload executed after initial delay.');
          }
        }, 1000);
      }
    } catch (error) {
      console.error('Error in LoadAdvAdKaora:', error);
    }
    // Cleanup function to clear the interval when the component unmounts or location changes
    return () => {
      try {
        if (initialTimeoutRef.current) {
          clearTimeout(initialTimeoutRef.current);
        }
      } catch (error) {
        console.error('Error in cleanup of LoadAdvAdKaora:', error);
      }
    };
  }, [location]); // Re-run effect when the location changes

  //useScript('//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4321911283255728');
  const loaded = useScript('https://cdn.adkaora.space/tennistalker/generic/prod/adk-init.js');
  return loaded ? <></> : <></>;
};

export default LoadAdvAdKaora;
